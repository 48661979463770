import { Component, Vue } from "vue-property-decorator";
import OpeningHours from "@/components/opening-hours/opening-hours.vue";
import Map from "@/components/map/map.vue";

@Component({
  components: {
    "opening-hours": OpeningHours,
    "google-map": Map,
  },
})
export default class Home extends Vue {
  $refs!: {
    about: HTMLElement;
  };

  private showFloatingButton: boolean = window.scrollY === 0;

  private onScroll(): void {
    this.showFloatingButton = window.scrollY === 0;
  }

  private scrollToAbout(): void {
    this.$vuetify.goTo(this.$refs.about, {
      duration: 700,
      offset: 0,
      easing: "easeInOutCubic",
    });
  }
}
