import { Component, Vue } from "vue-property-decorator";

@Component
export default class Map extends Vue {
  private isLoading = false;

  private allowMap(): void {
    this.isLoading = true;
    this.$store.commit("allowMap");
  }

  get allowGoogleMaps(): boolean {
    return this.$store.getters.allowGoogleMaps;
  }
}
